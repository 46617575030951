<template>
  <div class="size-selector">
    <div class="size-category">
      <h4>Größen</h4>
      <div class="size-list">
        <div v-for="size in sizes" :key="size" class="size-item">
          <div class="size-name">{{ size }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SizeSelector",
  props: {
    sizes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.size-selector {
  padding: 30px 0;
  h4 {
    color: var(--main);
    font-weight: 400;
  }
  .size-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .size-item {
      width: calc(50% - 5px);
      margin-top: 10px;
      border: 1px solid #e0e0e0;
      padding: 5px 10px;
      display: inline-block;
    }
  }
}
</style>
