<template>
  <div class="color-selector">
    <div
      v-for="(colorList, category) in colors"
      :key="category"
      class="color-category"
    >
      <h4>{{ category }}</h4>
      <div class="color-list">
        <div v-for="color in colorList" :key="color" class="color-item">
          <div class="color-swatch">
            <img
              v-if="getColorImage(color)"
              :src="getColorImage(color)"
              :alt="color"
            />
            <span v-else class="no-image"></span>
          </div>
          <div class="color-name">{{ color }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorSelector",
  props: {
    colors: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getColorImage(colorName) {
      // Diese Methode sollte die Bild-URL für den gegebenen Farbnamen zurückgeben
      // Sie müssen diese Logik entsprechend Ihren Anforderungen implementieren
      const colorImages = {
        "stonegrey":
          "https://www.rauchmoebel.de/fileadmin/_processed_/c/6/csm_035_stone_grey_703d993dca.png",
        "Dekor-Druck Eiche Sanremo hell":
          "https://www.rauchmoebel.de/fileadmin/_processed_/f/d/csm_050_Dekor-Druck_Eiche_Sanremo_hell_e3a947fb5b.png",
        "Dekor-Druck Eiche Sonoma":
          "https://www.rauchmoebel.de/fileadmin/_processed_/1/b/csm_059_Dekor-Druck_Eiche_Sonoma_0095a71152.png",
        "seidengrau":
          "https://www.rauchmoebel.de/fileadmin/_processed_/f/7/csm_068_seidengrau_1bba59bb0a.png",
        "Dekor-Druck Eiche Stirling":
          "https://www.rauchmoebel.de/fileadmin/_processed_/0/8/csm_077_Dekor-Druck_Eiche_Stirling_5cd0e8dfd3.png",
        "Dekor-Druck Eiche Wotan":
          "https://www.rauchmoebel.de/fileadmin/_processed_/0/3/csm_079_Dekor-Druck_Eiche_Wotan_47bc9b8831.png",
        "grau metallic":
          "https://www.rauchmoebel.de/fileadmin/_processed_/1/8/csm_082_grau-metallic_58efbbf10e.png",
        "alpinweiß":
          "https://www.rauchmoebel.de/fileadmin/_processed_/1/c/csm_045_alpinweiß_9299d6be7d.png",
        "Dekor-Druck Eiche Artisan":
          "https://www.rauchmoebel.de/fileadmin/_processed_/e/6/csm_072_Dekor-Druck_Eiche_Artisan_ef71441e92.png",
        "lavagrau": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/062_lavagrau.png",
        "Hochglanz Soft Grey": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0A4_Hochglanz%20soft%20grey.png",
        "Effektgrau Hochglanz": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0R6_Hochglanz%20Effektgrau.png",
        "Hochglanz weiß": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0A1_Hochglanz%20wei%C3%9F.png",
        "Champagner": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/042_Champagner.png",
        "Kristallglasspiegel": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas104_Kristallspiegel.png",
        "Glas seidengrau": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas168_Glas%20seidengrau.png",
        "Glas basalt": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas181_Glas%20basalt.png",
        "Kristallweißglas": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas104_Kristallspiegel.png",
        "Texline Leinenoptik naturfarben": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/047_Dekor-Druck%20Texline.png",
        "alufarbig":
          "https://www.rauchmoebel.de/fileadmin/_processed_/b/e/csm_007_alufarben_ec04970ac2.png",
        "schwarz matt": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/046_Schwarz%20matt.png",
        "Wildeiche Massiv": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/051_Wildeiche%20massiv.png",
        "chromfarben":
          "https://www.rauchmoebel.de/fileadmin/_processed_/9/6/csm_004_chromfarben_b8d8e561bb.png",
        "graphit": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/083_graphit.png",
        "Webstoff Silver grey": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0A7_Webstoff%20anthrazit.png",
        "Webstoff charcoal": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0A7_Webstoff%20anthrazit.png",
        "Lederoptik camel": "",
        "Lederoptik black": "",
        "Cordstoff Ivory": "",
        "Cordstoff Elephant": "",
        "Spiegel grau": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas103_Grauspiegel.png",
        "Glas fango": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas160_Glas%20Fango.png",
        "Glas schwarz": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas180_Glas%20schwarz.png",
        "Glas champagner": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/GLAS188_Glas_Champagner_glaenzend.png",
        "Cottage schwarz": "",
        "Ledertasche cognacbraun": "",
      };
      return colorImages[colorName] || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.color-selector {
  padding: 30px 0 0;
  border-top: 1px solid #e0e0e0;
}

.color-category {
  & + .color-category {
    margin-top: 60px;
  }

  h4 {
    color: var(--main);
    font-weight: 400;
  }

  .color-list {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
    text-align: center;

    .color-item {
      width: 33.333333%;
      padding: 0 5px;
      font-weight: 300;
      font-size: 0.8em;

      .color-swatch {
        margin: 15px 0 0;

        img,
        .no-image {
          border-radius: 100%;
          border: 1px solid #e0e0e0;
          -o-object-fit: cover;
          -o-object-position: center;
          object-fit: cover;
          object-position: center;
          width: 60px;
          height: 60px;
          display: inline-block;
        }

        .no-image {
          position: relative;
          overflow: hidden;

          &::before {
            content: "";
            display: block;
            width: 200%;
            height: 2px;
            background-color: #bbb;
            position: absolute;
            top: 50%;
            left: 30%;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &::after {
            content: "";
            display: block;
            width: 200%;
            height: 2px;
            background-color: #bbb;
            position: absolute;
            top: 50%;
            left: 70%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }
}
</style>
